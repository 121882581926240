
import { defineComponent } from "vue";
import { allBrandList, brandDownList } from "@/api/terminalList.js";
import { Cascader } from "ant-design-vue";
function recursion(array) {
  array.forEach((element) => {
    if (element.childBrandList.length) {
      element.children = element.childBrandList;
    }
    element.value = element.brandName;
    element.label = element.brandName;
    if (element.childBrandList.length) {
      recursion(element.childBrandList);
    }
  });
}
export default defineComponent({
  components: {
    Cascader,
  },
  data() {
    return {
      options: [],
      selectedOptions: [],
    };
  },
  props: {
    formState: {
      type: Object,
      default: {},
    },
    popupClassName: {
      type: String,
      default: "popupClassName",
    },
  },
  methods: {
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    //获取第一列数据
    getNewOptions() {
      allBrandList().then((res: any) => {
        recursion(res.data.data);
        this.options = res.data.data;
      });
    },
    changeCascader(value, selectedOptions) {
      // setTimeout(() => {
      //   let elems = document.querySelectorAll(".ant-cascader-menu-item");
      //   let removes = document.querySelectorAll(".brandSelect_tips");
      //   removes.forEach((el) => {
      //     el.remove();
      //   });
      //   if (elems) {
      //     elems.forEach((el) => {
      //       if (
      //         !el.innerHTML.includes("新增子品牌") &&
      //         selectedOptions.length <= 2
      //       ) {
      //         var div = document.createElement("div");
      //         div.innerHTML = "新增子品牌";
      //         div.className = "brandSelect_tips";
      //         div.onclick = function () {
      //           that.addNew();
      //         };
      //         el.append(div);
      //       }
      //     });
      //   }
      // }, 0);
      // this.selectedOptions = selectedOptions;

      (this as any).formState.brandCode =
        selectedOptions[selectedOptions.length - 1].brandCode;
      (this as any).formState.brandName = (
        this as any
      ).formState.brandNameList.join("/");
    },
    blurCascader() {
      (this as any).formState.brandName = (
        this as any
      ).formState.brandNameList.join("/");
    },
    addNew() {
      console.log((this as any).formState.brandNameList);
      console.log(this.selectedOptions);
      setTimeout(() => {
        alert((this as any).formState.brandNameList);
      }, 100);
    },
  },
  mounted() {
    this.getNewOptions();
  },
});
