<template>
  <Modal
    v-model:visible="visible"
    centered
    @ok="handleOk"
    class="checkModal"
    :confirmLoading="confirmLoading"
    :zIndex="200"
    width="1200px"
    :okButtonProps="{ disabled: computedDisabled() }"
  >
    <!-- {{ keepRecord }} -->
    <template #title>
      <DecorateModalTitle modalTitle="校验单品信息" />
    </template>
    <div class="title" style="margin-top: 10px">平台单品列表保留数据</div>
    <Form
      :model="formState"
      :rules="rules"
      ref="formRef"
      :label-col="{ style: { width: '80px' } }"
    >
      <Table
        rowKey="id"
        :columns="columns"
        :data-source="dataTop"
        :pagination="false"
        size="small"
        :scroll="{ x: '100%' }"
      >
        <!-- 表头插槽 -->
        <template #brandTitle>
          <div>
            <span> 品牌 </span>
            <Popover trigger="hover" placement="top">
              <template #content>
                <div class="titleTips">
                  <div class="weight">找不到对应品牌？</div>
                  <div class="line">
                    <span class="tips">·</span
                    ><span>该页面仅支持选择已有品牌数据，不支持新增品牌。</span>
                  </div>
                  <div class="line">
                    <span class="tips">·</span
                    ><span
                      >如有新增需求，建议去
                      <span
                        class="decoration"
                        @click="
                          () => {
                            this.$router.push('/brand');
                          }
                        "
                        >品牌管理</span
                      >
                      先新增品牌，再进行此操作。</span
                    >
                  </div>
                </div>
              </template>
              <span class="sortBox">
                <QuestionCircleOutlined
                  style="color: #5a80b0"
                  class="question"
                />
              </span>
            </Popover>
          </div>
        </template>
        <template #categoryTitle>
          <div>
            <span> 品类 </span>
            <Popover trigger="hover" placement="top">
              <template #content>
                <div class="titleTips">
                  <div class="weight">找不到对应品类？</div>
                  <div class="line">
                    <span class="tips">·</span
                    ><span>该页面仅支持选择已有品类数据，不支持新增品类。</span>
                  </div>
                  <div class="line">
                    <span class="tips">·</span
                    ><span
                      >如有新增需求，建议去
                      <span
                        class="decoration"
                        @click="
                          () => {
                            this.$router.push('/category');
                          }
                        "
                        >品类管理</span
                      >
                      先新增品类，再进行此操作。</span
                    >
                  </div>
                </div>
              </template>
              <span class="sortBox">
                <QuestionCircleOutlined
                  style="color: #5a80b0"
                  class="question"
                />
              </span>
            </Popover>
          </div>
        </template>

        <template #serialNumber="{ index }">
          <span class="serialNumber">
            {{ index + 1 }}
          </span>
        </template>

        <template #spName="{ record }">
          <Form-item v-if="isEdit" name="spName">
            <Input v-model:value="formState.spName" @keyup.enter="done()" />
          </Form-item>

          <div class="terminalNameContainer" v-else>
            <div>
              <div class="terminalNameBox longBox">
                <span :title="record.spName"> {{ record.spName }}</span>
              </div>
              <!-- <div
              class="terminalNameBox longBox"
              style="color: #ccc"
              :title="record.spCode"
            >
              {{ record.spCode }}
            </div> -->
            </div>
          </div>
        </template>

        <template #spBarCode="{ record }">
          <div class="terminalNameBox" :title="record.spBarCode">
            <span>
              {{ record.spBarCode }}
            </span>
          </div>
        </template>

        <template #spNickName="{ record }">
          <div>
            <Form-item v-if="isEdit" name="spNickName">
              <Input
                v-model:value="formState.spNickName"
                @keyup.enter="done()"
              />
            </Form-item>

            <span v-else>
              {{ record.spNickName }}
            </span>
          </div>
        </template>

        <template #stockUnitCode="{ record }">
          <div>
            <Form-item v-if="isEdit" name="stockUnitCode">
              <Select
                v-if="isEdit"
                v-model:value="formState.stockUnitCode"
                @keyup.enter="done()"
              >
                <Option
                  v-for="(item, index) in listBaseValue"
                  :key="index"
                  :value="item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </Form-item>

            <span v-else>
              {{ record.stockUnitCode }}
            </span>
          </div>
        </template>

        <template #salesPrice="{ record }">
          <div>
            <Form-item v-if="isEdit" name="salesPrice">
              <Input
                v-if="isEdit"
                v-model:value="formState.salesPrice"
                @keyup.enter="done()"
              />
            </Form-item>

            <span v-else>
              {{ record.salesPrice }}
            </span>
          </div>
        </template>

        <template #shelfLife="{ record }">
          <div>
            <Form-item v-if="isEdit" name="shelfLife">
              <Input
                v-if="isEdit"
                v-model:value="formState.shelfLife"
                @keyup.enter="done()"
              />
            </Form-item>

            <span v-else>
              {{ record.shelfLife }}
            </span>
          </div>
        </template>

        <template #spImage="{}">
          <div>
            <!-- {{ computedUrl(firstImg) }} -->
            <Upload
              name="file"
              list-type="picture-card"
              @change="handleChange"
              :before-upload="beforeUpload"
              :customRequest="customRequest"
              v-if="!computedUrl(firstImg) && isEdit"
              v-model:file-list="filelist"
              :showUploadList="false"
            >
              <div class="upload-text">
                <!-- <PictureOutlined style="font-size: 20px" /> -->
                <div class="ant-upload-text">点击上传</div>
              </div>
            </Upload>
            <div class="imgBox" v-if="computedUrl(firstImg)">
              <Image
                :src="firstImg"
                class="previewPhoto"
                style="width: 80px; height: 60px; object-fit: cover"
              />
              <div class="mask"></div>
              <div class="editBox">
                <span class="span" @click="previewImg">
                  <EyeFilled />
                  查看
                </span>
                <!-- <span style=" color: #999">|</span> -->
                <Upload
                  class="notUpload"
                  name="file"
                  @change="handleChange"
                  :before-upload="beforeUpload"
                  :customRequest="customRequest"
                  :showUploadList="false"
                  v-if="isEdit"
                  v-model:file-list="filelist"
                >
                  <span class="span"> <EditFilled /> 编辑 </span>
                </Upload>
              </div>
              <!-- <CloseCircleFilled class="imgIcon" @click="changeImg" /> -->
            </div>
          </div>
          <!-- <div v-else>
            <Image
              v-if="computedImgurl(record)"
              style="width: 40px; height: 40px; object-fit: cover"
              :src="record.imgUrl"
            />
          </div> -->
        </template>

        <template #brandName="{ record }">
          <!-- 
         name="brandName"
         
           -->
          <Form-item v-if="isEdit" name="brandNameList">
            <BrandSelect
              v-if="isEdit"
              @keyup.enter="done()"
              :formState="formState"
              style="width: 100%"
            />
          </Form-item>
          <span v-else>{{ record.brandName }}</span>
        </template>

        <template #categoryName="{ record }">
          <!--  name="categoryName"
          
           -->
          <Form-item v-if="isEdit" name="categoryNameList">
            <CategorySelect
              v-if="isEdit"
              :formState="formState"
              @keyup.enter="done()"
            />
          </Form-item>
          <span v-else>{{ record.categoryName }}</span>
        </template>

        <template #packageSize="{ record }">
          <div v-if="isEdit" style="display: flex; align-items: center">
            <Form-item v-if="isEdit" name="sizeLong" style="flex: 1">
              <Input v-model:value="formState.sizeLong" @keyup.enter="done()" />
            </Form-item>

            *
            <Form-item v-if="isEdit" name="sizeWide" style="flex: 1">
              <Input v-model:value="formState.sizeWide" @keyup.enter="done()" />
            </Form-item>

            *
            <Form-item v-if="isEdit" name="sizeHigh" style="flex: 1">
              <Input v-model:value="formState.sizeHigh" @keyup.enter="done()" />
            </Form-item>
          </div>
          <span v-else>{{ record.size }}</span>
        </template>

        <template #handle="{}">
          <Button
            type="link"
            style="display: flex; align-items: center"
            @click="done()"
          >
            <EditOutlined style="color: #008bcf" v-if="!isEdit" />
            <FileDoneOutlined style="color: #008bcf" v-else />
            <span :style="{ color: '#008BCF' }">{{
              !isEdit ? "编辑" : "完成"
            }}</span>
          </Button>
        </template>
      </Table>
    </Form>
    <div class="title" style="margin-top: 20px">相同条码的单品数据</div>
    <!-- 
      style="max-height: 300px; overflow: auto"
      :scroll="{ x: '100%', y: dataBottom.length >= 5 ? 300 : 0 }"
     -->
    <Table
      rowKey="id"
      :columns="columnsSimilar"
      :data-source="dataBottom"
      :pagination="false"
      :loading="loading"
      size="small"
      :scroll="{ x: '100%', y: 300 }"
    >
      <template #serialNumber="{ index }">
        <span class="serialNumber">
          {{ index + 1 }}
        </span>
      </template>

      <template #spName="{ record }">
        <div class="terminalNameContainer">
          <div>
            <div class="terminalNameBox longBox">
              <span :title="record.spName"> {{ record.spName }}</span>
            </div>
            <!-- <div
              class="terminalNameBox longBox"
              style="color: #ccc"
              :title="record.spCode"
            >
              {{ record.spCode }}
            </div> -->
          </div>
        </div>
      </template>

      <template #spBarCode="{ record }">
        <div class="terminalNameBox" :title="record.spBarCode">
          <span>
            {{ record.spBarCode }}
          </span>
        </div>
      </template>

      <template #spImage="{ record }">
        <Image
          v-if="computedImgurl(record)"
          style="width: 40px; height: 40px; object-fit: cover"
          :src="record.imgUrl"
        />
      </template>

      <template #packageSize="{ record }">
        {{ record.sizeLong }} <span v-if="record.sizeWide">*</span>
        {{ record.sizeWide }} <span v-if="record.sizeHigh">*</span>
        {{ record.sizeHigh }}
      </template>

      <!-- <template #handle="{ record }">
        <Button
          type="link"
          :disabled="record.spBarStatus == 1"
          style="display: flex; align-items: center"
          @click="replace(record)"
        >
          <img
            src="@/assets/icon-svg/source/刷新.svg"
            alt=""
            style="margin-right: 1px; position: relative; top: -1px"
          />
          <span :style="{ color: '#008BCF' }">{{ "替换" }}</span>
        </Button>
      </template> -->
    </Table>
  </Modal>
</template>
<script>
import { defineComponent } from "vue";
import {
  Modal,
  Input,
  Table,
  Image,
  Button,
  Select,
  Form,
  Upload,
  message,
  Popover,
} from "ant-design-vue";
import {
  EditOutlined,
  FileDoneOutlined,
  PlusOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  EyeFilled,
  EditFilled,
  PictureOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import {
  productCheck,
  listBaseValueByCode,
  imageUpload,
  updateLog,
  productUpdate,
} from "@/api/terminalList.js";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import {
  singleProductCheckColumns,
  singleProductSimilarColumns,
} from "@/api/fixedData.js";
import BrandSelect from "@/components/singleProduct/BrandSelect.vue";
import CategorySelect from "@/components/singleProduct/CategorySelect.vue";
import { SingleProductRules } from "@/rules/rules.js";
const { Option } = Select;
const { Item } = Form;
export default defineComponent({
  components: {
    BrandSelect,
    CategorySelect,
    DecorateModalTitle,
    Modal,
    Input,
    Table,
    Image,
    Button,
    EditOutlined,
    FileDoneOutlined,
    Select,
    Option,
    Form,
    FormItem: Item,
    Upload,
    PlusOutlined,
    CloseCircleFilled,
    LoadingOutlined,
    EyeFilled,
    EditFilled,
    PictureOutlined,
    QuestionCircleOutlined,
    Popover,
  },
  props: {},
  data() {
    return {
      visible: false, //校验弹窗
      confirmLoading: false, //校验确定按钮loading
      columns: singleProductCheckColumns,
      columnsSimilar: singleProductSimilarColumns,
      dataTop: [],
      dataBottom: [],
      loading: false,
      isEdit: false,
      formState: {},
      listBaseValue: [],
      fileUrl: "",
      firstImg: "",
      imgFlag: true,
      filelist: [],
      notUploaded: true,
      rules: SingleProductRules,
      keepRecord: "",
    };
  },
  methods: {
    show(record) {
      this.formState = record;
      this.isEdit = false;
      this.initialization(record);
      if (record) {
        this.keepRecord = JSON.parse(JSON.stringify(record));
      }
      this.visible = true;
      this.loading = true;
      // this.loading = false;
      // this.dataBottom = [
      //   {
      //     stockUnitCode: "ml",
      //     brandName: "喜之郎1",
      //     spName: "喜之郎1",
      //     sizeLong: null,
      //     salesPrice: 12.0,
      //     sizeWide: null,
      //     spBarCode: "2434231",
      //     spImgUrl: "",
      //     categoryCode: "categoryXN5W5XZAHFW",
      //     spCode: "STS000XN3U96WDFFW",
      //     categoryName: "喜之郎1",
      //     imgUrl:null,
      //     sizeHigh: null,
      //     id: 188,
      //     spNickName: "",
      //     shelfLife: null,
      //     brandCode: "brandXNMPZS98UFW",
      //   },
      // ];
      productCheck({
        spBarCode: record.spBarCode,
        // pageSize: 1000000, //待注释掉
        // pageNum: 1, //待注释掉
      })
        .then((res) => {
          this.loading = false;
          this.dataBottom = res.data.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initialization(record) {
      this.firstImg = record.imgUrl;
      this.formState.brandNameList = this.formState.brandName.split("/");
      this.formState.categoryNameList = this.formState.categoryName.split("/");
      if (this.formState.size) {
        this.formState.sizeLong = this.formState.size.split("*")[0];
        this.formState.sizeWide = this.formState.size.split("*")[1];
        this.formState.sizeHigh = this.formState.size.split("*")[2];
      }

      this.dataTop = [record];
    },
    replace(record) {
      this.formState = JSON.parse(JSON.stringify(record));
      this.initialization(record);
    },
    computedDisabled() {
      let keep = this.keepRecord;
      let formState = this.formState;
      let flag =
        keep.stockUnitCode === formState.stockUnitCode &&
        keep.spNickName === formState.spNickName &&
        keep.spImgUrl === formState.spImgUrl &&
        keep.categoryName === formState.categoryName &&
        keep.brandName === formState.brandName &&
        keep.spName === formState.spName &&
        keep.salesPrice === formState.salesPrice &&
        keep.sizeLong === formState.sizeLong &&
        keep.sizeWide === formState.sizeWide &&
        keep.sizeHigh === formState.sizeHigh &&
        keep.shelfLife === formState.shelfLife;
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    //计算返回的图片url值是否真实存在的图片
    computedImgurl(record) {
      if (record.imgUrl) {
        if (record.imgUrl.includes(".jp") || record.imgUrl.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedUrl(url) {
      if (url) {
        if (url.includes(".jp") || url.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    //上传图片
    handleChange(info) {
      if (info.fileList.length < 1) {
        this.notUploaded = true;
      }
    },
    //检验图片格式
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("图片大小应小于5M,请重新上传", 4);
        fileList.length = 0;
      }
      return isJpgOrPng && isLt5M;
    },
    customRequest(data) {
      const formData = new FormData();
      let name = data.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", data.file);
      formData.append("module", `singleproduct`);
      formData.append("scene", `image`);
      formData.append("format", name);
      this.confirmLoading = true;
      imageUpload(formData).then((res) => {
        this.fileUrl = res.data.data.fileUrl;
        this.firstImg = res.data.data.absoluteFileUrl;
        this.formState.imgUrl = res.data.data.absoluteFileUrl;
        this.formState.spImgUrl = res.data.data.fileUrl;
        this.confirmLoading = false;
        this.notUploaded = false;
        data.onSuccess();
      });
    },
    changeImg() {
      this.imgFlag = true;
      this.notUploaded = true;
      this.fileUrl = "";
    },
    //预览图片
    previewImg() {
      let photo = document.querySelector(".previewPhoto");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
    done() {
      if (this.isEdit) {
        this.$refs.formRef.validate().then(() => {
          this.isEdit = !this.isEdit;
        });
      } else {
        this.isEdit = !this.isEdit;
      }
    },
    //校准确定
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        let params1 = {
          id: this.formState.id,
          spName: this.formState.spName,
          spCode: this.formState.spCode,
          spBarCode: this.formState.spBarCode,
          spNickName: this.formState.spNickName,
          stockUnitCode: this.formState.stockUnitCode,
          categoryCode: this.formState.categoryCode,
          categoryName: this.formState.categoryNameList.join("/"),
          brandCode: this.formState.brandCode,
          brandName: this.formState.brandNameList.join("/"),
          salesPrice: this.formState.salesPrice,
          shelfLife: this.formState.shelfLife,
          sizeLong: this.formState.sizeLong,
          sizeWide: this.formState.sizeWide,
          sizeHigh: this.formState.sizeHigh,
          spImgUrl: this.formState.spImgUrl,
          checkStatus: 2,
          spBarStatus: 2,
        };
        let params2 = {
          spName: this.keepRecord.spName,
          spCode: this.keepRecord.spCode,
          spBarCode: this.keepRecord.spBarCode,
          spNickName: this.keepRecord.spNickName,
          stockUnitCode: this.keepRecord.stockUnitCode,
          categoryCode: this.keepRecord.categoryCode,
          categoryName: this.keepRecord.categoryNameList.join("/"),
          brandCode: this.keepRecord.brandCode,
          brandName: this.keepRecord.brandNameList.join("/"),
          salesPrice: this.keepRecord.salesPrice,
          shelfLife: this.keepRecord.shelfLife,
          sizeLong: this.keepRecord.sizeLong,
          sizeWide: this.keepRecord.sizeWide,
          sizeHigh: this.keepRecord.sizeHigh,
          spImgUrl: this.fileUrl || null,
        };
        this.confirmLoading = true;
        let P1 = productUpdate(params1);
        let P2 = updateLog(params2);
        Promise.all([P1, P2])
          .then((res) => {
            // console.log(res);
            if (res[0].data.success && res[1].data.success) {
              message.success("校验成功");
              this.visible = false;
              this.confirmLoading = false;
              this.fileUrl = null; //清空缓存
              this.firstImg = ""; //清空缓存
              this.$emit("todoTeload");
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      });
    },
  },
  mounted() {
    //获取单位列表
    listBaseValueByCode().then((res) => {
      if (res.data.success) {
        this.listBaseValue = res.data.data;
      }
    });
  },
});
</script>
<style lang="scss" scoped>
.title {
  color: #000000;
  font-weight: 600;
  margin-bottom: 10px;
}
.serialNumber {
  color: #262626;
  font-weight: 600;
}
.terminalNameContainer {
  display: flex;
  .iconbox {
    margin-right: 5px;
    font-size: 13px;
  }
}
.terminalNameBox {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.longBox.terminalNameBox {
  width: 150px;
}
.upload-text {
  color: #bfbfbf;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  //   .ant-upload-text {
  //     margin-top: 10px;
  //   }
}
.uploadBtn {
  width: 80px;
  height: 60px !important;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.imgBox {
  width: 80px;
  position: relative;
  .imgIcon {
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
    color: #008bcf;
    display: none;
    font-size: 16px;
  }
  .editBox {
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: none;
    .span {
      cursor: pointer;
      color: #fff;
    }
  }
}
.mask {
  width: 80px;
  height: 60px;
  opacity: 0.6;
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.imgBox:hover .imgIcon {
  display: block;
}
.imgBox:hover .mask {
  display: block;
}
.imgBox:hover .editBox {
  z-index: 10;
  display: block;
}
.question {
  font-size: 14px;
}
.titleTips {
  .line {
    line-height: 20px;
  }
  .weight {
    font-size: 14px;
    font-weight: 900;
    line-height: 26px;
  }
  .tips {
    font-size: 14px;
    font-weight: 900;
    margin-right: 7px;
  }
  .decoration {
    text-decoration: underline;
    color: rgb(0, 139, 207);
    cursor: pointer;
  }
}
</style>
<style lang="scss" >
.checkModal .ant-modal-body {
  padding: 0 30px !important;
}
.checkModal .ant-upload:not(.notUpload .ant-upload) {
  width: 78px !important;
  height: 53px !important;
  background: #fafafa;
  border-radius: 2px;
  padding: 0;
  margin: 0;
}
.checkModal .ant-form-item {
  margin: 0;
}
</style>