
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
  Checkbox,
  Select,
  Image,
  Modal,
} from "ant-design-vue";
import {
  UnorderedListOutlined,
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  StopOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  PictureFilled,
  SyncOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import {
  disableSingleProductById,
  allBrandList,
  brandDownList,
  // exportSingleProductList,
  listSingleProduct,
  categoryList,
} from "@/api/terminalList.js";
import { singleProductColumns } from "@/api/fixedData.js";
import PreviewModal from "@/components/singleProduct/PreviewModal.vue";
import CheckModal from "@/components/singleProduct/CheckModal.vue";

let { Group: CheckboxGroup } = Checkbox;
export default defineComponent({
  components: {
    Modal,
    CheckModal,
    Col,
    Row,
    Input,
    Button,
    Cascader,
    SettingOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    CloudUploadOutlined, //上传图标
    CloudDownloadOutlined, //下载图标
    FileAddFilled, //新建图标
    StopOutlined, //电源图标
    TagsFilled,
    UnorderedListOutlined,
    Table,
    Pagination,
    Popover,
    PreviewModal,
    Switch,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    PictureFilled,
    SyncOutlined,
    SearchOutlined,
    Select,
    Image,
  },
  data() {
    return {
      categoryOptions: [],
      brandOptions: [],
      //数据相关
      spNameOrSpCode: "",
      spBarCode: "",
      categoryName: [],
      brandName: [],
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //拓展字段
      customData: [],
      keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //table的样式
      batchStyle: "middle",
      checkStatus: null,
      spBarStatus: null,
      spBarStatusOptions: [
        // { value: "", label: "全部" },
        { value: 1, label: "唯一" },
        { value: 2, label: "不唯一" },
      ],
      checkStatusOptions: [
        // { value: "", label: "全部" },
        { value: 1, label: "未校准" },
        { value: 2, label: "已校准" },
      ],
    };
  },
  methods: {
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getTerminal();
    },
    //品类联级选择懒加载
    loadDataCategory(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      categoryList(targetOption.categoryCode).then((res: any) => {
        targetOption.loading = false;
        let newOptions = res.data.data.map((item) => {
          return {
            ...item,
            value: item.categoryName,
            label: item.categoryName,
            isLeaf: !item.isHave,
          };
        });
        targetOption.children = newOptions;
        this.categoryOptions = [...this.categoryOptions];
      });
    },
    //品牌联级选择
    loadDataBrand(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      brandDownList(targetOption.brandCode).then(() => {
        targetOption.loading = false;
        //
        let newOptions = targetOption.childBrandList.map((item) => {
          let checkIsLeaf;
          if (item.childBrandList.length) {
            checkIsLeaf = false;
          } else {
            checkIsLeaf = true;
          }
          return {
            ...item,
            value: item.brandName,
            label: item.brandName,
            isLeaf: checkIsLeaf,
          };
        });
        targetOption.children = newOptions;
        this.brandOptions = [...this.brandOptions];
      });
    },
    //计算品牌
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    //跳转到终端详情页面
    terminalDetails(id) {
      if (id === undefined) {
        id = "new";
      }
      if (this.$route.path.includes("singleProductApp")) {
        this.$router.push(`/singleProductApp/details/${id}`);
      } else {
        (this as any).$router.push(`/singleProductDetails/${id}`);
      }
    },
    //计算终端状态
    computedStatus(status) {
      switch (status) {
        case false:
          return "禁用";
        case true:
          return "启用";
        default:
          return "系统错误";
      }
    },
    //禁用终端 单个
    disableSingleProductById(id, status) {
      status = status == "禁用" ? "启用" : "禁用";
      let params = {
        id: id,
        status: status,
      };
      disableSingleProductById(params).then((res) => {
        if (res.data.success) {
        }
        this.getTerminal();
      });
    },
    //批量禁用
    disableBatch(id) {
      let params = {
        ids: [...id],
        status: 0,
      };
      disableSingleProductById(params).then((res) => {
        //
        if (res.data.success) {
          message.success("批量禁用成功");
        }
        // else {
        //   message.error(res.data.data);
        // }
        this.getTerminal();
      });
    },
    //获取单品列表
    getTerminal() {
      this.loading = true;
      listSingleProduct({
        spNameOrSpCode: this.spNameOrSpCode,
        spBarCode: this.spBarCode,
        checkStatus: this.checkStatus,
        spBarStatus: this.spBarStatus,
        categoryName: this.categoryName.join("/"),
        brandName: this.brandName.join("/"),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      })
        .then((res) => {
          if (res.data.success) {
            let result = res.data.data;
            this.total = result.totalRecord;
            this.data = result.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.spNameOrSpCode = "";
      this.spBarCode = "";
      this.categoryName = [];
      this.brandName = [];
      this.checkStatus = null;
      this.spBarStatus = null;
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNum = 1;
      this.getTerminal();
      this.pageCurrent = 1;
    },
    //跳转到导入页面
    batchImport() {
      if (this.$route.path.includes("singleProductApp")) {
        this.$router.push("/singleProductApp/batchImport");
      } else {
        this.$router.push("/masterData/singleProduct/batchImport");
      }
    },
    //预览图片
    previewPicture(url) {
      (this as any).$refs.showPreModal.showModal(url);
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == "禁用") {
        return false;
      } else {
        return true;
      }
    },
    //计算返回的图片url值是否真实存在的图片
    computedImgurl(record) {
      if (record.imgUrl) {
        if (record.imgUrl.includes(".jp") || record.imgUrl.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    calibration(record) {
      let data = JSON.parse(JSON.stringify(record));
      (this as any).$refs.CheckModal.show(data);
    },
    getBrandList() {
      //获取品牌
      allBrandList().then((res: any) => {
        function recursion(array) {
          array.forEach((element) => {
            if (element.childBrandList.length) {
              element.children = element.childBrandList;
            }
            element.value = element.brandName;
            element.label = element.brandName;
            if (element.childBrandList.length) {
              recursion(element.childBrandList);
            }
          });
        }
        recursion(res.data.data);
        this.brandOptions = res.data.data;
      });
    },
    checkPrice(item) {
      var x = item || 0;
      var f = parseFloat(x); // 解析一个字符串，返回一个浮点数；

      var f = Math.round(x * 100) / 100;
      var s = f.toString(); // 把一个逻辑值转换为字符串，并返回结果；
      var rs = s.indexOf("."); // 返回某个指定的字符串值在字符串中首次出现的位置；如果字符串值没有出现，返回-1；

      // 没有小数点时：
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.spNameOrSpCode = "";
        this.spBarCode = "";
        this.categoryName = [];
        this.brandName = [];
      }
      this.selectedRowKeys = [];
      this.pageNum = this.pageCurrent;
      this.getTerminal();
    },
  },
  mounted() {
    this.getBrandList();
    //获取一级品类
    categoryList("").then((res: any) => {
      let newOptions = res.data.data.map((item) => {
        let checkIsLeaf = item.isHave;
        return {
          ...item,
          value: item.categoryName,
          label: item.categoryName,
          isLeaf: !checkIsLeaf,
        };
      });
      this.categoryOptions = newOptions;
      //
    });
    //获取列表信息
    this.getTerminal();
    (this as any).columns = singleProductColumns;
  },
});
