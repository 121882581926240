<template>
  <!-- <Modal v-model:visible="visible" :footer="null" centered style="text-align: center;"> -->
  <Image
    :src="baseUrl"
    :width="0"
    class="imgbaseUrl"
    style="height: 0; width: 0; display: none"
  />
  <!-- 轮播效果现在不用了暂时 -->
  <!-- <Carousel
      :after-change="onChange"
      :dots="false"
      arrows
      dots-class="slick-dots slick-thumb"
      ref="aaa"
    >
      <template #customPaging="props">
        <a>
          <img :src="getImgUrl(props.i)" />
        </a>
      </template>
      <div v-for="item in 4" :key="item">
      <img :src="baseUrl" />
      </div>
    </Carousel> -->
  <!-- <div class="custom-slick-arrow" style="left: 30px;" @click="prev">
      <LeftCircleOutlined />
    </div>
    <div class="custom-slick-arrow" style="right: 30px;" @click="next">
      <RightCircleOutlined />
    </div> -->
  <!-- </Modal> -->
</template>
<script>
import { defineComponent } from "vue";
import { Modal, Carousel, Image } from "ant-design-vue";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    Modal,
    Carousel,
    LeftCircleOutlined,
    RightCircleOutlined,
    Image,
  },
  data() {
    return {
      visible: false,
      baseUrl: "",
    };
  },
  methods: {
    showModal(url) {
      this.baseUrl = url;
      let photo = document.querySelector(".imgbaseUrl");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
    // onChange(current) {
    //
    // },
    // getImgUrl(i) {
    //   return `${this.baseUrl}abstract0${i + 1}.jpg`;
    // },
    // prev(){
    //   this.$refs.aaa.prev()
    // },
    // next(){
    //   this.$refs.aaa.next()
    // }
  },
});
</script>
<style lang="scss" scoped>
.img {
  width: 95%;
}
/* For demo */
// .ant-carousel :deep(.slick-dots) {
//   position: relative;
//   height: auto;
// }
// .ant-carousel :deep(.slick-slide img) {
//   border: 5px solid #fff;
//   display: block;
//   margin: auto;
//   max-width: 80%;
// }
// .ant-carousel :deep(.slick-arrow) {
//   display: none !important;
// }
// .ant-carousel :deep(.slick-thumb) {
//   bottom: 0px;
// }
// .ant-carousel :deep(.slick-thumb li) {
//   width: 60px;
//   height: 45px;
// }
// .ant-carousel :deep(.slick-thumb li img) {
//   width: 100%;
//   height: 100%;
//   filter: grayscale(100%);
// }
// .ant-carousel :deep .slick-thumb li.slick-active img {
//   filter: grayscale(0%);
// }
// .custom-slick-arrow {
//   position: absolute;
//   top: 50%;
//   font-size: 25px;
//   cursor: pointer;
//   color: #ccc;
//   transform: translateY(-50%);
// }
</style>