
import { defineComponent } from "vue";
import { categoryList } from "@/api/terminalList.js";
import { Cascader } from "ant-design-vue";
export default defineComponent({
  components: {
    Cascader,
  },
  data() {
    return {
      options: [],
    };
  },
  props: {
    formState: {
      type: Object,
      defaul: {},
    },
  },
  methods: {
    loadData(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      return categoryList(targetOption.categoryCode).then((res: any) => {
        targetOption.loading = false;
        let newOptions = res.data.data.map((item) => {
          return {
            ...item,
            value: item.categoryName,
            label: item.categoryName,
            isLeaf: !item.isHave,
          };
        });
        targetOption.children = newOptions;
        this.options = [...this.options];
      });
    },
    //获取第一列数据
    getNewOptions(length = 5) {
      categoryList("").then((res: any) => {
        let newOptions = res.data.data.map((item) => {
          let checkIsLeaf = item.isHave;
          return {
            ...item,
            value: item.categoryName,
            label: item.categoryName,
            isLeaf: !checkIsLeaf,
          };
        });
        this.options = newOptions;
        this.recursion(this.options, length);
      });
    },
    recursion(array, length) {
      if (length === 1) {
        return;
      }
      array.forEach((element) => {
        if (
          element.categoryName ==
          (this as any).formState.categoryNameList[
            (this as any).formState.categoryNameList.length - length
          ]
        ) {
          categoryList(element.categoryCode).then((res: any) => {
            let newOptions = res.data.data.map((item) => {
              let checkIsLeaf = item.isHave;
              return {
                ...item,
                value: item.categoryName,
                label: item.categoryName,
                isLeaf: !checkIsLeaf,
              };
            });
            element.children = newOptions;
            length--;
            this.recursion(newOptions, length);
          });
        }
      });
    },
    changeCascader(value, selectedOptions) {
      (this as any).formState.categoryCode =
        selectedOptions[selectedOptions.length - 1].categoryCode;
      (this as any).formState.categoryName = (
        this as any
      ).formState.categoryNameList.join("/");
    },
    blurCascader() {
      (this as any).formState.categoryName = (
        this as any
      ).formState.categoryNameList.join("/");
    },
  },
  mounted() {
    let length = 0;
    if ((this as any).formState.categoryNameList) {
      length = (this as any).formState.categoryNameList.length;
    }
    this.getNewOptions(length);
  },
});
